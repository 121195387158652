import React from "react";
import Text from "../../../components/Text";
import LsqOptionBox from "../../../components/LsqOptionBox";
import { leadControlsConstants } from "../../../constants/globalConstant";
import { Tag } from "primereact/tag";
import { ReactComponent as Recommend } from "../../../assets/svgIcons/lsq_recommend.svg";

export default function PageOptions({ setCurrentPage }) {
  return (
    <div className={`flex flex-column flex-start h-full p-3 gap-2`}>
      <div className={`flex flex-column gap-1`}>
        <Text type="T2B" color="var(--text-primary)">
          {leadControlsConstants.CREATE_PAGE}
        </Text>
        <Text type="T4" color="var(--sub-text)">
          {leadControlsConstants.CHOOSE_AN_OPTION}
        </Text>
      </div>
      <LsqOptionBox
        heading={leadControlsConstants.CUSTOM_LANDING_PAGE}
        description={leadControlsConstants.CUSTOM_LANDING_PAGE_DESCRIPTION}
        tag={
          <Tag style={{ background: "var(--B100)" }}>
            <div className={`flex gap-1 p-1`}>
              <Recommend />
              <Text type="T5B" color="var(--B800)">
                {leadControlsConstants.RECOMMENDED}
              </Text>
            </div>
          </Tag>
        }
        clickHandler={() => setCurrentPage(leadControlsConstants.WIDGETS_PAGE)}
      />
      <LsqOptionBox
        heading={leadControlsConstants.CLASSIC_MENU_TABS}
        description={leadControlsConstants.CLASSIC_MENU_TABS_DESCRIPTION}
        tag={
          <Tag style={{ background: "var(--N20)" }}>
            <Text type="T5B" color="var(--N80)">
              {leadControlsConstants.BASIC}
            </Text>
          </Tag>
        }
        clickHandler={() =>
          setCurrentPage(leadControlsConstants.MENU_TABS_PAGE)
        }
      />
    </div>
  );
}
