import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  globalConstants,
  leadControlsConstants,
} from "../../constants/globalConstant.js";
import styles from "./styles.module.css";
import { leadControlSideMenuOptions } from "../../utils/leadControlsUtils.js";
import LeadCard from "./LeadCard/index.js";
import { LeadTitleEditor } from "./LeadTitleEditor.js";
import Text from "../../components/Text/index.js";
import CustomButton from "../../components/CustomButton/index.js";
import { ReactComponent as PublishIcon } from "../../assets/svgIcons/publish.svg";
import { ReactComponent as Hamburger } from "../../assets/svgIcons/Menu.svg";
import InfoBanner from "../../components/InfoBanner/index.js";
import LeadActions from "./LeadActions/index.js";
import ToastMessage from "../../components/ToastMessage";
import {
  setShowSidePanel,
  setSidePanelExpanded,
} from "../../reducers/homePageSlice.js";
import LeadPage from "./LeadPage/index.js";
import LsqSwitchV2 from "../../components/LsqSwitchV2/index.js";
import MobilePreview from "./MobilePreview/index.js";
import LeadTypeSelector from "./LeadTypeSelector.js";
import {
  getLeadTypes,
  resetLeadControls,
  resetSelectedLeadControls,
  saveLeadActions,
  saveLeadCard,
  saveLeadPage,
} from "../../reducers/leadControlsSlice.js";

export default function LeadControls() {
  const [publishBanner, setPublishBanner] = useState(false);
  const [showLeadType, setShowLeadType] = useState(true);
  const [showLeadTypeError, setShowLeadTypeError] = useState(false);
  const {
    toastMessages,
    selectedLeadControl,
    isLeadConfigurationDirty,
    isLeadConfigSaveLoading,
    leadTypes,
  } = useSelector((state) => state.leadControls);
  const dispatch = useDispatch();
  const [leadControlName, setLeadControlName] = useState(
    selectedLeadControl
      ? selectedLeadControl.name
      : leadControlsConstants.DEFAULT_LEAD_CONTROLS_NAME
  );
  const [currentTab, setCurrentTab] = useState(leadControlsConstants.CARD);
  const onTabChange = (label) => {
    setCurrentTab(label);
  };

  const getSaveButtonLabel = () => {
    if (isLeadConfigSaveLoading) return "Saving..";
    if (isLeadConfigurationDirty) return "Save";
    return "Saved";
  };

  const getSettingCards = () => {
    switch (currentTab) {
      case leadControlsConstants.CARD:
        return <LeadCard></LeadCard>;
      case leadControlsConstants.PAGE:
        return <LeadPage></LeadPage>;
      case leadControlsConstants.ACTIONS:
        return <LeadActions />;
      default:
        return <LeadCard></LeadCard>;
    }
  };

  const onSaveClicked = () => {
    switch (currentTab) {
      case leadControlsConstants.CARD:
        return dispatch(saveLeadCard());
      case leadControlsConstants.PAGE:
        return dispatch(saveLeadPage());
      case leadControlsConstants.ACTIONS:
        return dispatch(saveLeadActions());
      default:
        return;
    }
  };

  useEffect(() => {
    if (leadTypes.length === 0) {
      dispatch(getLeadTypes()).then(({ type }) => {
        if (type.includes(globalConstants.REJECTED)) setShowLeadTypeError(true);
      });
    }
    dispatch(setShowSidePanel(false));
    return () => {
      dispatch(resetLeadControls());
      dispatch(resetSelectedLeadControls());
    };
  }, []);

  useEffect(() => {
    return () => {
      onSaveClicked();
    };
  }, [currentTab]);

  const defaultValues = {
    mobilePreview: false,
  };
  const { watch, control } = useForm({ defaultValues: defaultValues });
  const isPreviewModeEnabled = watch("mobilePreview");

  return (
    <div
      className="flex h-screen w-screen align-items-center"
      data-testid="lead-controls-view"
    >
      <div className={`toast_container`} style={{ top: "2rem" }}>
        <ToastMessage toastMessages={toastMessages} />
      </div>
      <div
        className={`flex h-full w-full align-items-center flex-column overflow-hidden `}
      >
        <div
          className={`flex w-full align-items-center ${styles["lead-controls-top-bar"]}`}
        >
          <div
            className={`flex align-items-center  justify-content-center h-full `}
            style={{ width: "7rem" }}
          >
            <div
              className={`flex cursor-pointer p-2 ${styles["hamburger-icon"]}`}
              onClick={() => {
                dispatch(setShowSidePanel(true));
                dispatch(setSidePanelExpanded(true));
              }}
            >
              <Hamburger />
            </div>
          </div>
          <div
            className={`flex relative justify-content-center align-items-center w-full`}
          >
            <div
              className={`flex justify-content-center gap-1 absolute w-full`}
            >
              <LeadTitleEditor
                displayText={leadControlName}
                onSaveHandler={(text) => {
                  setLeadControlName(text);
                }}
                setShowLeadType={setShowLeadType}
              />
            </div>
            <div className={`flex justify-content-end gap-2 w-full`}>
              <div className={`flex p-1 px-2 box-border`}>
                <LsqSwitchV2
                  control={control}
                  switchName="mobilePreview"
                  label={leadControlsConstants.PREVIEW_MODE}
                  disabled={showLeadType ? true : false}
                />
              </div>
              <CustomButton
                varient="outline"
                label={getSaveButtonLabel()}
                data-testid="lead-controls-save"
                onClick={onSaveClicked}
                disabled={!isLeadConfigurationDirty || isLeadConfigSaveLoading}
              />
              <CustomButton
                varient="filled"
                icon={<PublishIcon />}
                label={leadControlsConstants.PUBLISH}
                onClick={() => setPublishBanner(true)}
                data-testid="lead-controls-publish"
              />
            </div>
          </div>
        </div>
        <div
          className={`flex h-full w-full align-items-center overflow-hidden relative`}
        >
          <div
            className={`flex flex-column gap-1 p-3 z-5 absolute ${styles["lead-control-side-panel"]}`}
          >
            {leadControlSideMenuOptions?.map((item) => {
              return (
                <div
                  key={item.tab}
                  className={`flex flex-column cursor-pointer gap-1 ${
                    styles["lead-controls-options"]
                  } ${
                    currentTab === item.tab &&
                    styles["active-lead-controls-option"]
                  }`}
                  onClick={() => onTabChange(item.tab)}
                  data-testid={item["data-testid"]}
                >
                  {item.displayIcon}
                  <Text type="T5B" color="var(--text-tertiary)">
                    {item.displayName}
                  </Text>
                </div>
              );
            })}
          </div>
          <div
            className={`flex h-full w-full ${styles["lead-controls-container"]}`}
          >
            {getSettingCards()}
          </div>
          <LeadTypeSelector
            state={showLeadType}
            setShowLeadType={setShowLeadType}
            leadControlName={leadControlName}
            setShowLeadTypeError={setShowLeadTypeError}
            showLeadTypeError={showLeadTypeError}
          />
          <MobilePreview state={isPreviewModeEnabled}></MobilePreview>
        </div>
      </div>
      <InfoBanner
        state={publishBanner}
        bannerImage={`/images/lead_controls.png`}
        bannerTitle={leadControlsConstants.LEAD_CONTROLS_COMING_SOON}
        bannerInfo={leadControlsConstants.LEAD_CONTROLS_PUBLISH_INFO}
        actionButtonLabel={leadControlsConstants.OKAY}
        hasDismissButton={false}
        actionButtonOnClick={() => {
          setPublishBanner(false);
        }}
      ></InfoBanner>
    </div>
  );
}
