import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Text from "../../../components/Text";
import {
  globalConstants,
  leadControlsWidgetConstants,
  placeholderConstants,
} from "../../../constants/globalConstant";
import { useForm } from "react-hook-form";
import ControlledMultiselect from "../../../components/LSQMultiSelect/ControlledMultiselect";
import {
  listOptionTemplate,
  valueTemplate,
} from "../../../utils/dropdownUtils";
import { Checkbox } from "primereact/checkbox";
import {
  fetchImageURL,
  formatImageFile,
  leadWidgetTileStyles,
  uploadImageInToBucket,
} from "../../../utils/leadControlsUtils";
import {
  addLeadWidget,
  resetLeadWidgetConfiguration,
  updateLeadWidget,
} from "../../../reducers/leadControlsSlice";
import { API_DELETE_IMAGES } from "../../../api/configurationScreenServices";
import WidgetConfigurationLayout from "./WidgetConfigurationLayout";
import { getOptionsExistInTheList } from "../../../utils/widgetUtils";

const OpportunitiesSettings = () => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const { selectedLeadControl, selectedLeadWidget } = useSelector(
    (state) => state.leadControls
  );
  const { metaData } = useSelector(
    (state) => state.leadControls.selectedWidgetConfiguration
  );
  const { widgetStyle, opportunityTypes } = metaData;
  const tileStyles = leadWidgetTileStyles;
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const defaultValues = {
    identifier: "Opportunities",
    widgetStyle: "Tile",
    tileStyle: "Icon",
    opportunityTypes: [],
    icon: {},
  };
  const {
    control,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
    reset,
  } = useForm({ defaultValues: defaultValues });
  const watchWidgetStyle = watch("widgetStyle");
  const tileStyle = watch("tileStyle");

  const selectAllHandler = (e) => {
    e.stopPropagation();
    if (e.target.checked) {
      setValue("opportunityTypes", opportunityTypes);
    } else setValue("opportunityTypes", []);
    setSelectAllChecked(e.target.checked);
  };

  useEffect(() => {
    !tileStyle && setValue("tileStyle", defaultValues.tileStyle);
    if (
      tileStyle === leadControlsWidgetConstants.IMAGE &&
      !(image && image?.uploadStatus === leadControlsWidgetConstants.SUCCESS)
    )
      setIsSaveDisabled(true);
    else {
      setIsSaveDisabled(false);
    }
    tileStyle === leadControlsWidgetConstants.ICON && setImage(null);
  }, [tileStyle, image]);

  useEffect(() => {
    if (selectedLeadWidget.mode === globalConstants.UPDATE) {
      const widgetDetails = selectedLeadWidget.widgetDetails.config;
      setValue("identifier", widgetDetails.identifier);
      setValue("widgetStyle", widgetDetails.widgetStyle);
      setValue("tileStyle", widgetDetails.tileStyle);
      setValue(
        "opportunityTypes",
        getOptionsExistInTheList(
          widgetDetails?.opportunityTypes,
          opportunityTypes
        )
      );
      setValue("icon", widgetDetails.icon);
      if (widgetDetails.tileStyle === leadControlsWidgetConstants.IMAGE) {
        fetchImageURL(widgetDetails.image).then((payload) => {
          setImage(payload);
        });
      }
    }
    return () => {
      reset();
    };
  }, [selectedLeadWidget, metaData]);

  const onSubmit = (data) => {
    const pageId = selectedLeadControl?.leadPage.id;
    const config = {
      identifier: data.identifier.trim(),
      widgetStyle: data.widgetStyle,
      opportunityTypes: data.opportunityTypes,
    };
    data.widgetStyle === leadControlsWidgetConstants.TILE &&
      (config["tileStyle"] = data.tileStyle);
    data.tileStyle === leadControlsWidgetConstants.IMAGE &&
      (config["image"] = {
        imageKey: image.imageKey,
      });
    data.tileStyle === leadControlsWidgetConstants.ICON &&
      (config["icon"] = data.icon);
    if (selectedLeadWidget.mode === globalConstants.CREATE)
      dispatch(
        addLeadWidget({
          pageId: pageId,
          widgetType: selectedLeadWidget.widgetDetails.id,
          postbody: { config: config },
        })
      );
    else
      dispatch(
        updateLeadWidget({
          pageId: pageId,
          widgetId: selectedLeadWidget.widgetDetails.id,
          postbody: { config: config },
          widgetType: selectedLeadWidget.widgetDetails.widgetType,
        })
      );
    dispatch(resetLeadWidgetConfiguration());
  };

  const selectAllPanel = () => {
    return (
      <div
        className="w-full flex align-items-center gap-1"
        style={{ padding: "0.5rem 1rem", height: "2.5rem" }}
      >
        <Checkbox
          onChange={selectAllHandler}
          checked={selectAllChecked}
          className="cursor-pointer"
          style={{
            height: "12px",
            width: "12px",
          }}
        ></Checkbox>
        <Text color="var(--N400)" type="T4B">
          {"Select All"}
        </Text>
      </div>
    );
  };

  const imageUploadHandler = (event) => {
    const imgFile = formatImageFile(event);
    setImage({
      ...imgFile,
      uploadStatus: leadControlsWidgetConstants.PENDING,
    });
    uploadImageInToBucket(imgFile).then((response) => {
      setImage(response);
    });
  };

  const onElementDeleted = () => {
    if (selectedLeadWidget.mode === globalConstants.CREATE) {
      let key = "";
      key += String(image.imageKey);
      API_DELETE_IMAGES(key);
    }
    setImage(null);
  };

  return (
    <WidgetConfigurationLayout
      control={control}
      errors={errors}
      widgetStyles={widgetStyle}
      widgetStyle={watchWidgetStyle}
      image={image}
      tileStyle={tileStyle}
      tileStyles={tileStyles}
      imageUploadHandler={imageUploadHandler}
      onSaveClicked={handleSubmit(onSubmit)}
      isSaveDisabled={isSaveDisabled}
      onImageDeletion={onElementDeleted}
      hasAdditionalSection={true}
      additionalSection={
        <ControlledMultiselect
          label={leadControlsWidgetConstants.SELECT_OPPORTUNITY_TYPE}
          isMandatory={true}
          errors={errors}
          className="w-full text-base lsq-input coach-opportunity-types"
          fieldName="opportunityTypes"
          control={control}
          options={opportunityTypes}
          optionLabel="name"
          placeholder={placeholderConstants.SELECT}
          itemTemplate={listOptionTemplate}
          valueTemplate={valueTemplate}
          panelClassName="lsq-multiselect-panel lsq-select-all-panel"
          maxSelectedLabels={-1}
          id="opportunity-types"
          panelHeaderTemplate={selectAllPanel}
          onChange={(e) => {
            setValue("opportunityTypes", e.target.value);
            setSelectAllChecked(
              e.target.value.length === opportunityTypes.length
            );
          }}
        />
      }
    />
  );
};

export default OpportunitiesSettings;
