import React, { useEffect, useState } from "react";
import Text from "../components/Text";
import CustomInput from "../components/CustomInput";
import CustomButton from "../components/CustomButton";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomDropdown } from "../components/CustomDropdown/CustomDropdown";
import ControlledMultiselect  from "../components/LSQMultiSelect/ControlledMultiselect"
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import { listOptionTemplate, valueTemplate } from "../utils/dropdownUtils";
import { getOptionsExistInTheList, getUpdatedName, getWidgetPreviewOfExperimentalWidget, getWidgetSignature } from "../utils/widgetUtils";
import { apiAddWidget, apiUpdateWidget } from "../reducers/previewPaneSlice";
import { globalConstants, stringConstants } from "../constants/globalConstant";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import { useDispatch, useSelector } from "react-redux";
import analytics from "../utils/analytics";
import { flattenTeamsHeirarchy, getTeamsById } from "../components/TeamAssignmentMultiselectTreeDropdown/TeamAssignmentMultiselectTreeDropdownUtils";
import { getUpdatedTeam } from "../utils/globalUtils";

const SmartViewCounterSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [smartViewTab, setSmartViewTab] = useState([]);
  const { selectedWidget, mode, selectedWidgetConfiguration, homepageId } =
    useWidgetEssentials();
  const { smartViews } = selectedWidgetConfiguration.metaData;
  const { teams} = useSelector(state => state.homepage)
  const linearTeamList = flattenTeamsHeirarchy(teams)
  const teamsById = getTeamsById(linearTeamList)

  const defaultValues = {
    title: stringConstants.MY_SMART_VIEWS,
    teams: [],
    smartView: {},
    smartViewTab: [],
  };

  const {
    control,
    formState: { errors },
    setValue,
    resetField,
    handleSubmit,
    watch,
    reset
  } = useForm({defaultValues: defaultValues});
 
  const onSubmit = (data) => {
    if(selectedWidget.isExperimental){
      getWidgetPreviewOfExperimentalWidget(selectedWidget.imageUrl, dispatch)
      return;
    }
    if(!data) return;
    let _smartView = {...data.smartView}
    delete _smartView['smartViewTabs']
    const config = {
      title: data.title.trim(),
      teams: data.teams,
      smartView: _smartView,
      smartViewTab: data.smartViewTab,
    };
    const metaValueData = getWidgetSignature(
      homepageId,
      selectedWidget?.id,
      config
    );
    const analytics_data = {
      'category': 'Widget',
      'type': 'click',
      'widgetType': 'SmartViewCounter',
      'smartView': metaValueData.config.smartView,
      'smartViewTabCount': metaValueData.config.smartViewTab?.count
    }
    if (mode === globalConstants.UPDATE) {
      const updatedMetaData = {
        widgetId: selectedWidgetConfiguration?.metaValue.widgetId,
        config: {
          ...metaValueData.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaData));
      analytics_data['widgetId'] = selectedWidgetConfiguration?.metaValue.widgetId
      analytics.sendEvent('Updating_SmartViewCounter', analytics_data);
    } else if (mode === globalConstants.CREATE) {
      dispatch(apiAddWidget(metaValueData));
      analytics.sendEvent('Adding_SmartViewCounter', analytics_data);
    }
    dispatch(resetWidgetConfiguration());
  }

  const onSmartViewChanged = (option) => {
    resetField("smartViewTab")
    setSmartViewTab(option.value.smartViewTabs)
    setValue("smartView", option.value)
  }

  const onTeamsChanged = (e) => {
    resetField("smartView")
    resetField("smartViewTab")
    setValue("teams" , e.target.value)
  }

  const onResetClicked = () => {
    reset()
    setSmartViewTab([])
  }

  const getSmartViewByTeams = () => {
    const teamIds = watch("teams").map(team => team.teamId)
    if(teamIds.length === 0) return smartViews
    const _smartViews = {}
    for(let team of teamIds){
      _smartViews[teamsById[team]?.SmartViewId] = true;
    }
    return smartViews?.filter(smartView => _smartViews[smartView.id])
  }

  useEffect(() => {
    if (mode === globalConstants.UPDATE) {
      const outdatedMetaValue = selectedWidgetConfiguration.metaValue;
      const outdatedConfig = outdatedMetaValue.config;
      const { title } = outdatedConfig;
      const smartView = getUpdatedName(
        smartViews,
        outdatedConfig.smartView
      );
      setValue("title", title);
      if(!Array.isArray(outdatedConfig.teams)){
        setValue("teams", linearTeamList.filter(team => outdatedConfig.teams[team.teamId]))
      }else{
        setValue("teams", getUpdatedTeam(linearTeamList, outdatedConfig.teams))
      }
      
      if (smartView) {
        setValue("smartView", smartView)
        setSmartViewTab(smartView.smartViewTabs)
        setValue("smartViewTab" , getOptionsExistInTheList(outdatedConfig.smartViewTab , smartView.smartViewTabs))
      }
    }
  }, []) 
  
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column w-full relative h-full">
      <div className="flex-1 lg:mx-6 md:mx-5 sm: mx-4 overflow-y-auto">
        <div className={`flex flex-column mt-5 gap-2`}>
          <Text type="T4"> {t("Widget Title")}</Text>
          <CustomInput
            control={control}
            errors={errors}
            fieldName="title"
            maxLength={30}
            className="lsq-input-bold"
            label="Display Name"
            isMandatory={true}
            data-testid="title"
          />
        </div>
        <div className={`flex flex-column mt-4 gap-2`}>
          <Text type="T4"> {t("Add Smart View")}</Text>
          <ControlledMultiselect
            label = "Teams"
            isMandatory = {false}
            errors={errors}
            className="w-full text-base lsq-input"
            fieldName="teams"
            control={control}
            options={linearTeamList}
            placeholder="Select Teams"
            itemTemplate={(option, props) => listOptionTemplate(option, props, "label")}
            panelClassName="lsq-multiselect-panel"
            id="teams-tab"
            optionLabel="label"
            onChange={onTeamsChanged}
            maxSelectedLabels={-1}
          />
          <CustomDropdown
            label= "Smart View"
            isMandatory = {true}
            errors={errors}
            className="w-full text-base lsq-input"
            fieldName="smartView"
            control={control}
            options={getSmartViewByTeams()}
            optionLabel="name"
            placeholder="Select"
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
            onChange = {onSmartViewChanged}
          />
          <ControlledMultiselect
            label = "Tab"
            isMandatory = {true}
            errors={errors}
            className="w-full text-base lsq-input"
            fieldName="smartViewTab"
            control={control}
            options={smartViewTab}
            optionLabel="name"
            placeholder="Select"
            itemTemplate={listOptionTemplate}
            panelClassName="lsq-multiselect-panel"
            selectionLimit={10}
            maxSelectedLabels={-1}
            id="smartview-tab"
            onChange={e => setValue("smartViewTab" , e.target.value)}
          />
        </div>
      </div>
      <div
        className={
          "flex flex-row align-items-center justify-content-end w-11 absolute bottom-0 pb-4 gap-2"
        }
      >
        <CustomButton
          type="reset"
          label="Reset"
          varient="text"
          onClick={onResetClicked}
        />
        <CustomButton type="submit" label="Save" varient="filled" />
      </div>
    </form>
  );
};

export default SmartViewCounterSettings;
