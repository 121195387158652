import cloneDeep from "lodash.clonedeep";
import { ReactComponent as CrossIcon } from "../../assets/svgIcons/lsq_close.svg";
import { ReactComponent as TeamsIcon } from "../../assets/svgIcons/lsq_teams.svg";
import { globalConstantValues } from "../../constants/globalConstant";
import { checkIfTeamExist } from "../../utils/globalUtils";
import Text from "../Text";

export const onCheckboxSelected = (e, fieldValue) => {
  const selectedNode = e.node;
  let modifiedValue = {
    ...fieldValue,
    [selectedNode.key]: { checked: true, partialChecked: false },
  };
  return modifiedValue;
};

export const onCheckboxUnselected = (e, fieldValue) => {
  const node = e.node;
  let modifiedValue = {
    ...fieldValue,
  };
  delete modifiedValue[node.key];
  return modifiedValue;
};

export const chipGenerator = (value, teamList) => {
  let list = [];
  let teamFlatListObj = {};
  teamList?.forEach((team) => (teamFlatListObj[team.teamId] = team));
  Object.keys(value).forEach((key) => {
    if (teamFlatListObj[key] && value[key].checked === true) {
      list.push(teamFlatListObj[key]);
    }
  });

  return list;
};
const getStyle = (isGreyedOut) => ({
  pointerEvents: isGreyedOut ? "none" : "all",
  opacity: isGreyedOut ? "0.5" : "1",
});

const depthFirstTraversalTeams = (
  teamHierarchy,
  mappedTeamsDashboards,
  dashboardId
) => {
  let stack = [];
  const Teams = teamHierarchy;
  let output = [...Teams];
  let isGreyedOut =
    mappedTeamsDashboards[output[0].key]?.length >=
      globalConstantValues.MAX_NUMBER_OF_TEAM_DASHBOARD &&
    !mappedTeamsDashboards[output[0].key]?.includes(parseInt(dashboardId));
  output[0] = {
    ...output[0],
    style: getStyle(isGreyedOut),
  };
  stack.push(output[0]);
  while (stack.length > 0) {
    const node = stack[stack.length - 1];
    stack.pop();
    if (node.children === undefined) continue;
    for (let i = node.children.length - 1; i >= 0; i--) {
      isGreyedOut =
        mappedTeamsDashboards[node.children[i].key]?.length >=
          globalConstantValues.MAX_NUMBER_OF_TEAM_DASHBOARD &&
        !mappedTeamsDashboards[node.children[i].key]?.includes(
          parseInt(dashboardId)
        );
      node.children[i] = {
        ...node.children[i],
        style: getStyle(isGreyedOut),
      };
      if (node?.children[i]?.children?.length === 0) {
        delete node.children[i].children;
      }

      stack.push(node.children[i]);
    }
  }
  return output;
};

export const teamListBuilder = (
  teamHierarchy,
  teamsDashboards,
  dashboardId
) => {
  if(teamHierarchy?.length === 0 || !teamsDashboards || !dashboardId){
    return {
      root: [],
    }
  }
  const mappedTeamsDashboards = Object.fromEntries(
    teamsDashboards.map((team) => [team.teamId, team.homepages])
  );
  const cloneTeamHierarchy = cloneDeep(teamHierarchy);
  const teamTreeList = {
    root: [
      ...depthFirstTraversalTeams(
        cloneTeamHierarchy,
        mappedTeamsDashboards,
        dashboardId
      ),
    ],
  };
  return teamTreeList;
};

const depthFirstTraversalTeamsForMenuBuilder = (
  teamHierarchy,
  mappedTeamMenu,
  menuId
) => {
  let stack = [];
  const Teams = teamHierarchy;
  let output = [...Teams];
  let isGreyedOut = mappedTeamMenu[output[0].key] && mappedTeamMenu[output[0].key] != parseInt(menuId);
  output[0] = {
    ...output[0],
    style: getStyle(isGreyedOut),
  };
  stack.push(output[0]);
  while (stack.length > 0) {
    const node = stack[stack.length - 1];
    stack.pop();
    if (node.children === undefined) continue;
    for (let i = node.children.length - 1; i >= 0; i--) {
      isGreyedOut = mappedTeamMenu[node.children[i].key] && mappedTeamMenu[node.children[i].key] != parseInt(menuId);
      node.children[i] = {
        ...node.children[i],
        style: getStyle(isGreyedOut),
      };
      if (node?.children[i]?.children?.length === 0) {
        delete node.children[i].children;
      }

      stack.push(node.children[i]);
    }
  }
  return output;
};

export const teamListBuilderForMenuBuilder = (teamHierarchy, teamMenu, mennuId) => {
  if (teamHierarchy?.length === 0 || !teamMenu) {
    return {
      root: [],
    };
  }
  const mappedTeamMenu = teamMenu
    ? Object.fromEntries(teamMenu?.map((team) => [team.teamId, team.menuId]))
    : [];
  const cloneTeamHierarchy = cloneDeep(teamHierarchy);
  const teamTreeList = {
    root: [
      ...depthFirstTraversalTeamsForMenuBuilder(
        cloneTeamHierarchy,
        mappedTeamMenu,
        mennuId
      ),
    ],
  };
  return teamTreeList;
};

export const onChipRemoval = (fieldValue, key) => {
  delete fieldValue[key];
  return fieldValue;
};

export const chipTemplate = (props) => {
  return (
    <div className="flex w-full h-full align-items-center justify-content-between">
      <div className="w-10 flex flex-row gap-2 align-items-center">
        <TeamsIcon style={{ width: "1rem" }} />
        <Text className="w-12 ellipsis" color="#314361" type="T4B">
          {props.label}
        </Text>
      </div>
      <CrossIcon
        onClick={props.onRemove}
        style={{ width: "1.2rem", fill: "#768196", cursor: "pointer" }}
      />
    </div>
  );
};

export const teamIdsToTreeList = (teamIds = [] , teamList = []) => {
  let treeTeamList = {};
  const treeObject = { checked: true, partialChecked: false };
  teamIds?.forEach((teamId) => {
    if(checkIfTeamExist(teamId , teamList)){
      treeTeamList[teamId] = treeObject;
    }
  });

  return treeTeamList;
};

const flattenObjectRecursively = (teamsHeirearchy) => {
  let array = []
  for (let teamHierarchy of teamsHeirearchy) {
    let team = {
      ...teamHierarchy
    }
    if(teamHierarchy.children){
      delete team["children"]
    }
    array.push(team)
    if(teamHierarchy.children && teamHierarchy.children.length > 0 ){
      array.push(...flattenObjectRecursively(teamHierarchy.children))
    }
  }
  return array
}


export const flattenTeamsHeirarchy = (teamsHeirearchy = {}) => {
  return flattenObjectRecursively(teamsHeirearchy)
}

export const getTeamsById = (flatTeamsList = []) => {
  return Object.fromEntries(flatTeamsList.map(team => [team.teamId, team])) 
}