import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash.clonedeep";
import { useTranslation } from "react-i18next";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import { validateWidgetSubmission } from "../utils/globalUtils";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import LsqSwitch from "../components/LsqSwitch";
import CustomRadioGroup from "../components/CustomRadioGroup";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import ImageGalleryEditor from "../components/ImageGalleryEditor/ImageGalleryEditor";
import { deleteImagesFromS3, getImageKeys } from "../utils/bannerUtils";
import analytics from "../utils/analytics";
import { Skeleton } from "primereact/skeleton";
import {
  bannerSettingsConstants,
  casaConstants,
  globalConstants,
  imageGallerySettingsConstants,
  stringConstants,
  widgetConstants,
} from "../constants/globalConstant";
import {
  fetchImageGalleryLinks,
  formatImages,
  generateWidgetMetaValueForImageGallery,
} from "../utils/imageGalleryUtils";
import {
  addWidget,
  apiAddWidget,
  apiUpdateWidget,
  deleteWidget,
  updateWidgetConfiguration,
} from "../reducers/previewPaneSlice";
import {
  getWidgetPreviewOfExperimentalWidget,
  getWidgetSignature,
} from "../utils/widgetUtils";
import { widgetTypes } from "../constants/globalEnums";

export const ImageGallerySettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [removedImageKeys, setRemovedImageKeys] = useState([]);
  const {
    selectedWidgetConfiguration,
    mode,
    selectedWidget,
    homepageId,
    previewPaneData,
  } = useWidgetEssentials();
  const { selectedHomepageId, homepagesById } = useSelector(
    (state) => state.homepage
  );
  const homepage = homepagesById[selectedHomepageId];
  const { hasDraftChanges, status } = homepage;
  const { layout, size } = selectedWidgetConfiguration.metaData;
  const widgetId =
    mode === globalConstants.CREATE
      ? imageGallerySettingsConstants.DEMO_GALLERY_IMAGE
      : selectedWidgetConfiguration?.metaValue.widgetId;
  const defaultValues = {
    title: stringConstants.IMAGE_GALLERY,
    layout: layout?.length > 0 && layout[0].value,
    size: size?.length > 0 && size[0].value,
    frame: false,
    images: [],
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = useForm({ defaultValues: defaultValues });

  const imageConfigurationDefaultValues = {
    label: "",
    originalImage: null,
    imageURL: null,
    uploadStatus: null,
    imageKey: null,
    imageName: null,
    navigateTo: {},
    externalLink: "",
    externalLinkOptions: "",
    internalLink: {},
    dynamicForm: {
      entity: "",
      workArea: null,
      form: {},
    },
  };

  const imageList = useFieldArray({
    control,
    name: "images",
  });

  const imageConfiguration = useForm({
    defaultValues: imageConfigurationDefaultValues,
  });

  const onSubmit = (data) => {
    if (selectedWidget.isExperimental) {
      getWidgetPreviewOfExperimentalWidget(selectedWidget.imageUrl, dispatch);
      return;
    }
    if (!validateWidgetSubmission(mode, previewPaneData, dispatch)) return;
    const config = {
      title: data.title,
      size: data.size,
      layout: data.layout,
      frame: data.frame,
      images: formatImages(data.images),
    };
    const metaValueData = getWidgetSignature(
      homepageId,
      selectedWidget?.id,
      config
    );
    const analytics_data = {
      category: "Widget",
      type: "click",
      widgetType: "imagegallery",
    };
    if (mode === globalConstants.UPDATE) {
      const updatedMetaData = {
        widgetId: widgetId,
        config: {
          ...metaValueData.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaData)).then(({ type }) => {
        if (
          type?.includes("fulfilled") &&
          !(status === casaConstants.PUBLISHED || hasDraftChanges)
        ) {
          deleteImagesFromS3(removedImageKeys);
        }
      });
      analytics_data["widgetId"] = widgetId;
      analytics.sendEvent("Updating_ImageGallery", analytics_data);
    } else if (mode === globalConstants.CREATE) {
      dispatch(apiAddWidget(metaValueData));
      analytics.sendEvent("Adding_ImageGallery", analytics_data);
    }
    dispatch(resetWidgetConfiguration());
  };

  const updateGalleryInPreviewPane = () => {
    const config = {
      title: getValues(imageGallerySettingsConstants.TITLE),
      layout: getValues(imageGallerySettingsConstants.LAYOUT),
      size: getValues(imageGallerySettingsConstants.SIZE),
      frame: getValues(imageGallerySettingsConstants.FRAME),
      images: getValues("images"),
    };
    const widgetMetaValue = generateWidgetMetaValueForImageGallery(
      config,
      widgetId
    );
    dispatch(updateWidgetConfiguration(widgetMetaValue));
  };

  const onImageGalleryReset = () => {
    reset();
    updateGalleryInPreviewPane();
    if (mode === globalConstants.CREATE) {
      const imageKeys = getImageKeys(imageList.fields);
      deleteImagesFromS3(imageKeys);
    }
  };

  const updatImageConfiguration = () => {
    const images = getValues("images");
    const config = {
      title: getValues(imageGallerySettingsConstants.TITLE),
      layout: getValues(imageGallerySettingsConstants.LAYOUT),
      size: getValues(imageGallerySettingsConstants.SIZE),
      frame: getValues(imageGallerySettingsConstants.FRAME),
      images:
        images.length === 0
          ? [
              {
                imageURL: bannerSettingsConstants.DEMO_SQUARE_IMAGE,
              },
            ]
          : images,
    };
    const widgetMetaValue = generateWidgetMetaValueForImageGallery(
      config,
      widgetId
    );
    dispatch(updateWidgetConfiguration(widgetMetaValue));
  };

  const onDisplaySizeUpdate = () => {
    if (mode === globalConstants.CREATE) {
      updatImageConfiguration();
    }
    if (mode === globalConstants.UPDATE) {
      const config = {
        ...getValues(),
        size: getValues(imageGallerySettingsConstants.SIZE),
      };
      const widgetMetaValue = generateWidgetMetaValueForImageGallery(
        config,
        widgetId
      );
      dispatch(updateWidgetConfiguration(widgetMetaValue));
    }
  };

  const onLayoutUpdate = () => {
    if (mode === globalConstants.CREATE) {
      updatImageConfiguration();
    }
    if (mode === globalConstants.UPDATE) {
      const config = {
        ...getValues(),
        layout: getValues(imageGallerySettingsConstants.LAYOUT),
      };
      const widgetMetaValue = generateWidgetMetaValueForImageGallery(
        config,
        widgetId
      );
      dispatch(updateWidgetConfiguration(widgetMetaValue));
    }
  };

  useEffect(() => {
    if (mode === globalConstants.CREATE) {
      const demoBanner = {
        widgetLayout: {
          type: globalConstants.FULL,
          widgetId: imageGallerySettingsConstants.DEMO_GALLERY_IMAGE,
          widgetType: widgetTypes.IMAGE_GALLERY,
        },
        widgetConfig: {
          homepageId: homepageId,
          widgetTemplateId: 23,
          config: {
            title: "Image Gallery",
            layout: layout?.length > 0 && layout[0].value,
            size: size?.length > 0 && size[0].value,
            frame: false,
            images: [
              {
                imageURL: imageGallerySettingsConstants.DEMO_SQUARE_IMAGE,
              },
            ],
          },
        },
      };
      dispatch(addWidget(demoBanner));
    } else if (mode === globalConstants.UPDATE) {
      const { title, size, layout, frame, images } =
        selectedWidgetConfiguration.metaValue.config;
      setValue(imageGallerySettingsConstants.TITLE, title);
      setValue(imageGallerySettingsConstants.LAYOUT, layout);
      setValue(imageGallerySettingsConstants.SIZE, size);
      setValue(imageGallerySettingsConstants.FRAME, frame);
      let updatedImages = cloneDeep(images);
      fetchImageGalleryLinks(images).then((images) => {
        images.forEach((image, index) => {
          updatedImages[index][imageGallerySettingsConstants.IMAGE_URL] =
            image.imageURL;
          updatedImages[index][imageGallerySettingsConstants.ORIGINAL_IMAGE] = {
            objectURL: image.imageURL,
          };
          updatedImages[index][imageGallerySettingsConstants.UPLOAD_STATUS] =
            imageGallerySettingsConstants.SUCCESS;
        });
        imageList.replace(updatedImages);
      });
    }
    return () => {
      if (mode === globalConstants.CREATE) {
        dispatch(
          deleteWidget({
            widgetId: imageGallerySettingsConstants.DEMO_GALLERY_IMAGE,
          })
        );
      } else if (mode === globalConstants.UPDATE) {
        const widgetMetaValue = generateWidgetMetaValueForImageGallery(
          selectedWidgetConfiguration.metaValue.config,
          widgetId
        );
        dispatch(updateWidgetConfiguration(widgetMetaValue));
      }
    };
  }, []);

  const onFrameUpdate = (e) => {
    setValue(imageGallerySettingsConstants.FRAME, e.target.value);
    if (mode === globalConstants.CREATE) {
      updatImageConfiguration();
    }
    if (mode === globalConstants.UPDATE) {
      const config = {
        ...getValues(),
        frame: getValues(imageGallerySettingsConstants.FRAME),
      };
      const widgetMetaValue = generateWidgetMetaValueForImageGallery(
        config,
        widgetId
      );
      dispatch(updateWidgetConfiguration(widgetMetaValue));
    }
  };

  const checkIsSaveDisabled = (fields) => {
    if (fields.length === 0) return true;
    for (let imageElement of fields) {
      if (imageElement.uploadStatus !== imageGallerySettingsConstants.SUCCESS)
        return true;
    }
    return false;
  };

  return (
    <form
      className="flex flex-column w-full h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-column overflow-y-auto mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3 flex-1">
        <div className="flex flex-column gap-2 mt-2">
          <CustomInput
            control={control}
            errors={errors}
            fieldName="title"
            maxLength={30}
            className="lsq-input-bold"
            label={t(widgetConstants.WIDGET_TITLE)}
            isMandatory={false}
            data-testid="title"
          />
        </div>
        {layout ? (
          <CustomRadioGroup
            control={control}
            label={t(widgetConstants.SELECT_LAYOUT)}
            options={layout}
            radioName="layout"
            onChange={() => onLayoutUpdate()}
          />
        ) : (
          <Skeleton type="rectangle" width="60%" height="2rem" />
        )}
        {size ? (
          <CustomRadioGroup
            control={control}
            label={t(widgetConstants.SELECT_SIZE)}
            options={size}
            radioName="size"
            optionLabelValue={true}
            onChange={() => onDisplaySizeUpdate()}
          />
        ) : (
          <Skeleton type="rectangle" width="60%" height="2rem" />
        )}
        <LsqSwitch
          control={control}
          label={t(widgetConstants.FRAME)}
          switchName="frame"
          onChange={(e) => onFrameUpdate(e)}
          textColor={"var(--N90)"}
          data-testid="frame-switch"
        />
        <ImageGalleryEditor
          mode={mode}
          imageList={imageList}
          imageConfiguration={imageConfiguration}
          setRemovedImageKeys={setRemovedImageKeys}
          updateGalleryInPreviewPane={updateGalleryInPreviewPane}
        />
      </div>
      <div className={`flex align-items-center justify-content-end gap-2 p-4`}>
        <CustomButton
          type="reset"
          label={t(globalConstants.RESET)}
          varient="text"
          onClick={onImageGalleryReset}
          data-testid="reset"
        />
        <CustomButton
          type="submit"
          label={t(globalConstants.SAVE)}
          varient="filled"
          disabled={checkIsSaveDisabled(imageList.fields)}
          data-testid="save"
        />
      </div>
    </form>
  );
};
