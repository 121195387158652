import React from "react";
import { ReactComponent as Add } from "../../../assets/svgIcons/lsq_add.svg";
import Text from "../../../components/Text";
import styles from "./styles.module.css";

export default function EmptyLeadPagePreview({ label }) {
  return (
    <div
      className={`flex flex-column justify-content-center align-items-center p-3 ${styles["lead-page-preview-backgrond"]}`}
    >
      <div className={`flex flex-column align-items-center gap-2 `}>
        <Add fill="#AFB1B6" />
        <Text type="H2" color="var(--sub-text)">
          {label}
        </Text>
      </div>
    </div>
  );
}
