import React from "react";
import styles from "./styles.module.css";
import PropTypes from "prop-types";
import Text from "../Text";
import { ReactComponent as Forword } from "../../assets/svgIcons/lsq_forward.svg";

export default function LsqOptionBox({
  heading,
  description,
  tag,
  clickHandler,
}) {
  return (
    <div className={`flex flex-column gap-1 p-3 ${styles["option-box"]}`}>
      <Text type="T3B" color="var(--text-primary)">
        {heading}
      </Text>
      <Text type="T5" color="var(--sub-text)">
        {description}
      </Text>
      <div className={`flex justify-content-between align-items-center`}>
        {tag}
        <Forword
          onClick={clickHandler}
          className="cursor-pointer"
          data-testid="lsq-option-box-click"
        />
      </div>
    </div>
  );
}

LsqOptionBox.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  tag: PropTypes.element,
  clickHandler: PropTypes.func,
};
