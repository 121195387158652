import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import SidePanel from "../../../components/SidePanel";
import Text from "../../../components/Text";
import { ReactComponent as Add } from "../../../assets/svgIcons/lsq_add.svg";
import { ReactComponent as Cross } from "../../../assets/svgIcons/cross.svg";
import LeadActionsList from "./LeadActionsList";
import QuickActions from "./QuickActions";
import { leadControlsConstants } from "../../../constants/globalConstant";
import { getLeadActionsMetaValue } from "../../../reducers/leadControlsSlice";
import SelectedActionList from "./SelectedActionList";

export default function LeadActions() {
  const dispatch = useDispatch();
  const { leadActions } = useSelector((state) => state.leadControls);
  const [editAction, setEditAction] = useState({});
  const showRightPanel = leadActions?.selectedActions.length !== 0;

  useEffect(() => {
    leadActions.actions.length == 0 && dispatch(getLeadActionsMetaValue());
  }, []);

  return (
    <div
      className={`flex w-full h-full justify-content-center`}
      data-testid="lead-actions-view"
    >
      <div
        className={`flex flex-column justify-content-end align-items-center p-3 ${styles["lead-action-preview-backgrond"]}`}
      >
        <div
          className={`flex flex-column gap-2 align-items-center ${styles["lead-quick-action-preview"]}`}
        >
          <div className={`flex w-full justify-content-center relative p-3`}>
            <Text type="H2B">{leadControlsConstants.QUICK_ACTIONS}</Text>
            <div
              className={`flex w-full absolute justify-content-end mr-3`}
            >
              <Cross />
            </div>
          </div>
          {leadActions.selectedActions.length <= 0 ? (
            <div
              className={`flex flex-column align-items-center justify-content-center h-full p-3 gap-2`}
            >
              <Add fill="#AFB1B6" />
              <Text type="H2" color="#AFB1B6" className="word-break">
                {leadControlsConstants.CLICK_THE_ACTION_TO_ADD}
              </Text>
            </div>
          ) : (
            <QuickActions editAction={editAction} />
          )}
        </div>
      </div>
      <SidePanel
        direction={"left"}
        state={true}
        style={{
          position: "absolute",
          width: "21%",
          left: "7rem",
          boxShadow: "-4px 0px 8px 0px #00000005",
        }}
      >
        <LeadActionsList />
      </SidePanel>
      {showRightPanel && (
        <SidePanel
          state={showRightPanel}
          style={{
            position: "absolute",
            width: "21%",
            boxShadow: "-4px 0px 8px 0px #00000005",
            transform: "none",
          }}
        >
          <SelectedActionList setEditAction={setEditAction} />
        </SidePanel>
      )}
    </div>
  );
}
