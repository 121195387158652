import cloneDeep from "lodash.clonedeep";
import { v4 as uuidv4 } from "uuid";
import { bannerSettingsConstants, imageGallerySettingsConstants, inRowWidgets, standaloneWidgets, widgetConstants } from "../constants/globalConstant";
import { countLimit, formatWidgetConfig } from "./globalUtils";
import { widgetTemplateIds, widgetTypes } from "../constants/globalEnums";

export const findFirstBlankSectionInLayout = (previewPane) => {
  const {sections} = previewPane;
  let resultIndex = -1
  for(let index = 0; index < sections.length ; index++){
    const noOfWidgetInSection = findNumberOfWidgetsInASection(index, previewPane)
    if(noOfWidgetInSection === 0){
      resultIndex = index;
      break;
    }
  }
  return resultIndex
}

export const findNumberOfWidgetsInASection = (sectionIndex , previewPane) => {
    let count = 0;
    previewPane.sections[sectionIndex].rows.forEach((row) => {
      const widgetCount = row.widgets.length;
      count += widgetCount;
      const widgetId = row?.widgets[0]?.widgetId
      if (widgetId === "demoLauncherView" || widgetId === bannerSettingsConstants.DEMO_BANNER || widgetId === imageGallerySettingsConstants.DEMO_GALLERY_IMAGE) {
        count -= 1
      }
    })
    return count;
} 

export const findNumberOfWidgetsInTheLayout = (previewPane) => {
  let count = 0;
  previewPane.sections.forEach((_ , sectionIndex ) => {
    count += findNumberOfWidgetsInASection(sectionIndex , previewPane)
  })

  return count;
}


export const findSectionIndexWithId = (sectionId, previewPaneData) => {
  let sectionIndex = -1;
  for (let i = 0; i < previewPaneData.sections.length; i++) {
    let section = previewPaneData.sections[i];
    if (section.sectionId === sectionId) {
      sectionIndex = i;
      return sectionIndex;
    }
  }
};

export const findRowIndexWithID = (rowId, previewPaneData) => {
  for (let i = 0; i < previewPaneData.sections.length; i++) {
    let section = previewPaneData.sections[i];
    for (let j = 0; j < section.rows.length; j++) {
      if (section.rows[j].id === rowId) {
        return [i, j];
      }
    }
  }
};

export const reArrangeSections = (
  sourceIndex,
  destinationIndex,
  destinationDrop,
  previewPaneData
) => {
  let newPreviewPaneData = { ...previewPaneData };
  let newSections;
  let selectedSection = previewPaneData.selectedSection;
  if (destinationDrop === "delete") {
    if (newPreviewPaneData.sections.length === 1) return previewPaneData;
    newSections = [...previewPaneData.sections];
    newSections.splice(sourceIndex, 1);
    selectedSection =
      selectedSection + 1 > newSections.length
        ? newSections.length - 1
        : selectedSection;
  } else {
    newSections = [...previewPaneData.sections];
    const [removed] = newSections.splice(sourceIndex, 1);
    newSections.splice(destinationIndex, 0, removed);
  }

  newPreviewPaneData = {
    ...newPreviewPaneData,
    sections: newSections,
    selectedSection: selectedSection,
  };
  return newPreviewPaneData;
};

export const reArrangeWidgets = (
  sourceIndex,
  destinationIndex,
  sourceDrop,
  destinationDrop,
  previewPaneData
) => {
  let newPreviewPaneData = { ...previewPaneData };
  if (sourceDrop === destinationDrop) {
    let sectionIndex = findSectionIndexWithId(sourceDrop, previewPaneData);
    let newRowList = [...previewPaneData.sections[sectionIndex].rows];
    const [removedRow] = newRowList.splice(sourceIndex, 1);
    newRowList.splice(destinationIndex, 0, removedRow);
    let newDestinationIndex = {
      ...newPreviewPaneData.sections[sectionIndex],
      rows: newRowList,
    };
    newPreviewPaneData.sections[sectionIndex] = newDestinationIndex;
    return newPreviewPaneData;
  } else {
    let sourceSectionIndex = findSectionIndexWithId(
      sourceDrop,
      previewPaneData
    );
    let destinationSectionIndex = findSectionIndexWithId(
      destinationDrop,
      previewPaneData
    );
    let sourceRowList = [...previewPaneData.sections[sourceSectionIndex].rows];
    const [removedRow] = sourceRowList.splice(sourceIndex, 1);
    let destinationRowList = [
      ...previewPaneData.sections[destinationSectionIndex].rows,
    ];
    destinationRowList.splice(destinationIndex, 0, removedRow);
    let newSourceSection = {
      ...newPreviewPaneData.sections[sourceSectionIndex],
      rows: sourceRowList,
    };

    let newDestinationSection = {
      ...newPreviewPaneData.sections[destinationSectionIndex],
      rows: destinationRowList,
    };
    newPreviewPaneData.sections[sourceSectionIndex] = newSourceSection;
    newPreviewPaneData.sections[destinationSectionIndex] =
      newDestinationSection;

    return newPreviewPaneData;
  }
};

export const reArrangeHorizontalWidgets = (
  sourceIndex,
  destinationIndex,
  sourceDrop,
  destinationDrop,
  previewPaneData
) => {
  if (sourceDrop === destinationDrop) {
    let newPreviewPaneData = { ...previewPaneData };
    const [sectionIndex, rowIndex] = findRowIndexWithID(
      destinationDrop,
      previewPaneData
    );
    let newRow = [
      ...previewPaneData.sections[sectionIndex].rows[rowIndex].widgets,
    ];
    const [removed] = newRow.splice(sourceIndex, 1);
    newRow.splice(destinationIndex, 0, removed);
    if (newRow.length > 2) return previewPaneData;
    newPreviewPaneData.sections[sectionIndex].rows[rowIndex].widgets = newRow;
    return newPreviewPaneData;
  } else {
    let newPreviewPaneData = { ...previewPaneData };
    const [sourceSectionIndex, sourceRowIndex] = findRowIndexWithID(
      sourceDrop,
      previewPaneData
    );
    const [destinationSectionIndex, destinationRowIndex] = findRowIndexWithID(
      destinationDrop,
      previewPaneData
    );
    let sourceRow = [
      ...previewPaneData.sections[sourceSectionIndex].rows[sourceRowIndex]
        .widgets,
    ];
    const [removed] = sourceRow.splice(sourceIndex, 1);
    let destinationRow = [
      ...previewPaneData.sections[destinationSectionIndex].rows[
        destinationRowIndex
      ].widgets,
    ];
    destinationRow.splice(destinationIndex, 0, removed);
    if (destinationRow.length > 2) return previewPaneData;
    newPreviewPaneData.sections[sourceSectionIndex].rows[
      sourceRowIndex
    ].widgets = sourceRow;
    newPreviewPaneData.sections[destinationSectionIndex].rows[
      destinationRowIndex
    ].widgets = destinationRow;
    if (sourceRow.length === 0) {
      newPreviewPaneData.sections[sourceSectionIndex].rows.splice(
        sourceRowIndex,
        1
      );
    }
    return newPreviewPaneData;
  }
};

export const onDragEndUtil = (result, previewPaneData) => {
  if (!result.destination) return previewPaneData;
  const sourceIndex = result.source.index;
  const destinationIndex = result.destination.index;
  const sourceDrop = result.source.droppableId;
  const destinationDrop = result.destination.droppableId;

  if (
    sourceDrop.localeCompare(destinationDrop) === 0 &&
    sourceIndex === destinationIndex
  )
    return previewPaneData;

  if (result.type === "Sections") {
    return reArrangeSections(
      sourceIndex,
      destinationIndex,
      destinationDrop,
      previewPaneData
    );
  } else if (result.type === "widgets") {
    return reArrangeWidgets(
      sourceIndex,
      destinationIndex,
      sourceDrop,
      destinationDrop,
      previewPaneData
    );
  } else if (result.type === "halfWidgets") {
    return reArrangeHorizontalWidgets(
      sourceIndex,
      destinationIndex,
      sourceDrop,
      destinationDrop,
      previewPaneData
    );
  }

  return previewPaneData;
};



export const addNewCanvasUtil = (previewPaneData) => {
  
  let sectionSize = previewPaneData.sections.length;
  if (sectionSize === countLimit.SECTION_COUNT) {
    return previewPaneData;
  }
  let newPreviewPaneData = { ...previewPaneData };
  newPreviewPaneData = {
    ...newPreviewPaneData,
    sections: [
      ...newPreviewPaneData.sections,
      { id: `section${sectionSize + 1}`, rows: [] },
    ],
  };

  return newPreviewPaneData;
};

export const addWidgetUtil = (
  widgetInformation,
  previewPaneData,
  selectedSection
) => {
  let index = -1;
  const { type } = widgetInformation;
  const isWidgetInsideARow = type === "half";

  if (isWidgetInsideARow) {
    for (
      let i = 0;
      i < previewPaneData.sections[selectedSection].rows.length;
      i++
    ) {
      let row = previewPaneData.sections[selectedSection].rows[i];
      if (row.widgets.length === 0||(row.widgets.length === 1 && row.widgets[0].type === "half")) {
        index = i;
        break;
      }
    }
  }
  let newPreviewPaneData = { ...previewPaneData };
  let row;
  if (index === -1) {
    row = {
      id: `row${uuidv4()}`,
      widgets: [
        {
          ...widgetInformation,
        },
      ],
    };
  } else {
    row = {
      ...previewPaneData.sections[selectedSection].rows[index],
    };

    row.widgets.push({
      ...widgetInformation,
    });
  }

  if (index === -1) {
    newPreviewPaneData.sections[selectedSection].rows.push(row);
  } else {
    newPreviewPaneData.sections[selectedSection].rows[index] = row;
  }

  return newPreviewPaneData;
};

export const findWidgetPosition = (widgetId, previewPaneData) => {
  for (
    let sectionIndex = 0;
    sectionIndex < previewPaneData.sections.length;
    sectionIndex++
  ) {
    let section = previewPaneData.sections[sectionIndex];
    for (let rowIndex = 0; rowIndex < section.rows.length; rowIndex++) {
      let row = section.rows[rowIndex];
      for (
        let widgetIndex = 0;
        widgetIndex < row.widgets.length;
        widgetIndex++
      ) {
        let widget = row.widgets[widgetIndex];
        if (widget.widgetId === widgetId) {
          const widgetPosition = {
            sectionIndex: sectionIndex,
            rowIndex: rowIndex,
            widgetIndex: widgetIndex,
          };
          return widgetPosition;
        }
      }
    }
  }
};

export const updateWidgetUtil = (previewPaneData, widgetId , type) => {
  const widgetPosition = findWidgetPosition(widgetId, previewPaneData);
  if (widgetPosition === undefined) return previewPaneData;
  const { sectionIndex, rowIndex, widgetIndex } = widgetPosition;
  previewPaneData.sections[sectionIndex].rows[rowIndex].widgets[
    widgetIndex
  ] = {
    ...previewPaneData.sections[sectionIndex].rows[rowIndex].widgets[
      widgetIndex
    ],
    type: type
  }
  if(previewPaneData.sections[sectionIndex].rows[rowIndex].widgets.length === 2){
    if(previewPaneData.sections[sectionIndex].rows[rowIndex].widgets[0].type === "full" || 
    previewPaneData.sections[sectionIndex].rows[rowIndex].widgets[1].type === "full"
    ){
      let newRow = {
        id: "row-" + uuidv4(),
        widgets: [
          {
            ...previewPaneData.sections[sectionIndex].rows[rowIndex].widgets[1]
          }
        ]
      }
      previewPaneData.sections[sectionIndex].rows[rowIndex].widgets.splice(1, 1);
      previewPaneData.sections[sectionIndex].rows.splice(rowIndex + 1, 0 , newRow)
    }
  }
  return previewPaneData;
};

export const deleteWidgetUtil = (previewPaneData, widgetId) => {
  const widgetPosition = findWidgetPosition(widgetId, previewPaneData);
  if (widgetPosition === undefined) return previewPaneData;
  const { sectionIndex, rowIndex, widgetIndex } = widgetPosition;
  previewPaneData.sections[sectionIndex].rows[rowIndex].widgets.splice(
    widgetIndex,
    1
  );
  if (
    previewPaneData.sections[sectionIndex].rows[rowIndex].widgets.length === 0
  ) {
    previewPaneData.sections[sectionIndex].rows.splice(rowIndex, 1);
  }
  return previewPaneData;
};

export const isWidgetInsideARow = (widgetInformation) => {
  const _widgetInformation = formatWidgetConfig(widgetInformation)
  const { widgetTemplateId } = _widgetInformation;
  return inRowWidgets.includes(widgetTemplateId) !== false
};

export const isStandaloneWidgetInSection = (widgetIdentifier) => {
  return standaloneWidgets.includes(widgetIdentifier)
}

export const getWidgetWidth = (widgetInformation) => {
  const { widgetTemplateId } = widgetInformation;
  if(isStandaloneWidgetInSection(widgetTemplateId)) return "100%"
  if(isWidgetInsideARow(widgetInformation)) return "49%"
  else return "95%"
}

export const doesSectionContainStandaloneWidget = (section) => {
  if(section.rows.length > 1) return false;
  else if(section.rows[0]?.widgets?.length > 1) return false;
  else if(isStandaloneWidgetInSection(section.rows[0]?.widgets[0]?.widgetType) && section.rows[0]?.widgets[0]?.widgetId !== bannerSettingsConstants.DEMO_BANNER &&
  section.rows[0]?.widgets[0]?.widgetId !== imageGallerySettingsConstants.DEMO_GALLERY_IMAGE) return true
  else return false;
}

export const getPublishedViewSections = (sections , WidgetConfiguration) => {
  let _sections = cloneDeep(sections)
  _sections.forEach(section => {
    section.rows?.forEach(row => {
      for(let i = 0 ; i < row.widgets.length ; i++){
        const widget = row.widgets[i]
        let _widget = {
          ...widget,
         ...WidgetConfiguration[widget.widgetId]
        }
        row.widgets[i] = _widget
      }
    })
  })

  return _sections
}

export const isWidgetEdgeToEdge = (section, widgetConfiguration) => {
  const edgeToEdgeWidgets = [widgetTemplateIds.BANNER, widgetTypes.BANNER]
  if(edgeToEdgeWidgets.includes(section?.rows[0]?.widgets[0]?.widgetType)){
    const widgetId = section.rows[0]?.widgets[0].widgetId
    const { config } = widgetConfiguration[widgetId] || {};
    if(config?.displayStyle === widgetConstants.EDGE_TO_EDGE) return true;
  }
  return false;
}