import React from "react";
import Text from "../../../components/Text";
import styles from "./styles.module.css";
import { useSelector, useDispatch } from "react-redux";
import { changeSelectedLeadWidget } from "../../../reducers/leadControlsSlice";
import { globalConstants } from "../../../constants/globalConstant";
import { getLeadWidgetTitlebyWidgetType } from "../../../utils/leadControlsUtils";
import { ReactComponent as DragIndicator } from "../../../assets/svgIcons/drag_indicator.svg";

export default function DetailedView({
  id,
  dragHandleProps,
  isDragging,
  disablePointerEvents,
}) {
  const dispatch = useDispatch();
  const { leadWidgetConfiguration, selectedLeadWidget } = useSelector(
    (state) => state.leadControls
  );
  const widgetInformation = leadWidgetConfiguration[id];
  const title = widgetInformation?.config.identifier;
  const isActiveWidget = selectedLeadWidget.widgetDetails.id === id;
  if (!widgetInformation) return <></>;
  return (
    <div
      className={`${styles["detailed-widget-container"]} ${
        isActiveWidget && styles["active-widget"]
      } ${isDragging && styles["active-widget"]} ${
        disablePointerEvents && `pointer-events-none`
      }`}
      style={{
        height:
          widgetInformation?.widgetType === "customwidget" ? "21rem" : "auto",
      }}
      onClick={() =>
        dispatch(
          changeSelectedLeadWidget({
            mode: globalConstants.UPDATE,
            widgetTitle: getLeadWidgetTitlebyWidgetType(
              widgetInformation.widgetType
            ),
            widgetDetails: widgetInformation,
          })
        )
      }
    >
      <div className="flex flex-column absolute mt-1 ml-6">
        <Text type="T4B" color="var(--text-focused)">
          {title}
        </Text>
      </div>
      {widgetInformation?.widgetType === "customwidget" ? (
        <div className="flex w-full h-full relative overflow-hidden border-round">
          <iframe
            target="_parent"
            src={`${widgetInformation.config.url}`}
            width={"100%"}
            height={"100%"}
            title={`${title}`}
          />
          <div className="flex h-full w-full absolute"></div>
        </div>
      ) : (
        <img
          className="w-full"
          src={`/images/leadWidgetsPreview/${widgetInformation?.widgetType}.png`}
        />
      )}
      <div
        className={`flex justify-content-center w-full align-items-center absolute ${styles["drag-indicator"]}`}
        style={{ top: "-1rem" }}
        {...dragHandleProps}
      >
        <div className={`${styles["drag-indicator-background"]}`}>
          <DragIndicator style={{ transform: "rotate(90deg)" }} />
        </div>
      </div>
    </div>
  );
}
