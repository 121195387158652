import cloneDeep from "lodash.clonedeep";
import {
  API_ADD_LEAD_WIDGET,
  API_DELETE_LEAD_WIDGET,
  API_GET_LEAD_ACTIONS_METADATA,
  API_GET_LEAD_CARD_META_DATA,
  API_GET_LEAD_FIELDS,
  API_GET_LEAD_PAGE_MENU_TABS,
  API_GET_LEAD_PAGE_WIDGETS,
  API_GET_LEAD_PAGE_WIDGET_METADATA,
  API_GET_LEAD_TYPES,
  API_GET_LEAD_WIDGET,
  API_POST_LEAD_CONTROLS,
  API_UPDATE_LEAD_ACTIONS,
  API_UPDATE_LEAD_CARD,
  API_UPDATE_LEAD_CONTROLS,
  API_UPDATE_LEAD_PAGE,
  API_UPDATE_LEAD_WIDGET,
} from "../api/configurationScreenServices";
import {
  globalConstants,
  leadControlsWidgetConstants,
  toastMessageConstants,
} from "../constants/globalConstant";
import {
  findLeadWidgetsIndexInRowWithID,
  formatLeadActionConfig,
  formatLeadCardConfig,
  formatLeadPageConfig,
  formatLeadTabsWithIsCustom,
  removeWidgetFromRow,
} from "../utils/leadControlsUtils";
const { createSlice, createAsyncThunk, nanoid } = require("@reduxjs/toolkit");

const initialState = {
  toastMessages: [],
  leadTypes: [],
  leadWidgets: {
    widgetTemplates: {
      status: globalConstants.PENDING,
      list: [],
    },
    widgetMetaData: {},
  },
  leadWidgetConfiguration: {},
  selectedWidgetConfiguration: {
    metaData: {},
    metaValue: {},
  },
  leadPageMenus: {
    menus: [],
    selectedMenus: [],
  },
  leadActions: {
    actions: [],
    selectedActions: [],
  },
  selectedLeadWidget: {
    mode: null,
    widgetTitle: null,
    widgetDetails: {},
  },
  leadCard: {
    allLeadFields: [],
    leadFields: [],
    leadActions: [],
  },
  selectedLeadCard: {},
  widgetsLayout: [],
  isLeadTypeLoading: false,
  selectedLeadType: null,
  selectedLeadControl: null,
  isLeadConfigurationDirty: false,
  isLeadConfigSaveLoading: false,
};

export const getLeadTypes = createAsyncThunk(
  "leadControls/getLeadTypes",
  async (_, thunkAPI) => {
    const response = await API_GET_LEAD_TYPES();
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getLeadFields = createAsyncThunk(
  "leadControls/getLeadFields",
  async (_, thunkAPI) => {
    const response = await API_GET_LEAD_FIELDS();
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const addLeadControls = createAsyncThunk(
  "leadControls/addLeadControls",
  async ({ postBody }, thunkAPI) => {
    const response = await API_POST_LEAD_CONTROLS(postBody);
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const updateLeadControls = createAsyncThunk(
  "leadControls/updateLeadControls",
  async ({ leadControlId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_LEAD_CONTROLS(leadControlId, postBody);
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    thunkAPI.dispatch(resetLeadControls());
    return data;
  }
);

export const getLeadCardMetaData = createAsyncThunk(
  "leadControls/getLeadCardMetaData",
  async (_, thunkAPI) => {
    const response = await API_GET_LEAD_CARD_META_DATA();
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const updateLeadCard = createAsyncThunk(
  "leadControls/updateLeadCard",
  async ({ leadControlId, cardId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_LEAD_CARD(
      leadControlId,
      cardId,
      postBody
    );
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getLeadPageMenuTabs = createAsyncThunk(
  "leadControls/getLeadPageMenuTabs",
  async (_, thunkAPI) => {
    const response = await API_GET_LEAD_PAGE_MENU_TABS();
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getAllLeadPageWidgets = createAsyncThunk(
  "leadControls/getAllLeadPageWidgets",
  async (_, thunkAPI) => {
    const response = await API_GET_LEAD_PAGE_WIDGETS();
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getLeadPageWidgetMetaData = createAsyncThunk(
  "leadControls/getLeadPageWidgetMetaData",
  async (widgetType, thunkAPI) => {
    const { leadWidgets } = thunkAPI.getState().leadControls;
    const { widgetMetaData } = leadWidgets;
    if (widgetMetaData[widgetType])
      return { _widgetMetaData: widgetMetaData, widgetIdentifier: widgetType };
    const metadataResponse = await API_GET_LEAD_PAGE_WIDGET_METADATA(
      widgetType
    );
    const { data, status } = metadataResponse;
    if (status !== 200) {
      const errorMessage =
        metadataResponse.message ||
        toastMessageConstants.FAILED_TO_FETCH_METADATA;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const _widgetMetaData = { ...widgetMetaData };
    _widgetMetaData[widgetType] = data;
    return { _widgetMetaData, widgetIdentifier: widgetType };
  }
);

export const getLeadPageWidgetMetaValue = createAsyncThunk(
  "leadControls/getLeadPageWidgetMetaValue",
  async ({ pageId, widgetId }, thunkAPI) => {
    const { leadWidgetConfiguration } = thunkAPI.getState().leadControls;
    if (leadWidgetConfiguration[widgetId]) {
      return thunkAPI.fulfillWithValue(leadWidgetConfiguration[widgetId]);
    }
    const response = await API_GET_LEAD_WIDGET(pageId, widgetId);
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const addLeadWidget = createAsyncThunk(
  "leadControls/addLeadWidget",
  async ({ pageId, widgetType, postbody }, thunkAPI) => {
    const response = await API_ADD_LEAD_WIDGET(pageId, postbody);
    const { status, data } = response;
    const { widgetsLayout } = thunkAPI.getState().leadControls;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const clonedLayout = cloneDeep(widgetsLayout);
    const detailedView =
      data.config.widgetStyle === leadControlsWidgetConstants.DETAILED;
    clonedLayout.push({
      rowId: `row-${nanoid()}`,
      detailedView: detailedView,
      widgets: [data.id],
    });
    thunkAPI.dispatch(updateLeadWidgtesLayout(clonedLayout));
    return { ...data, widgetType };
  }
);

export const updateLeadWidget = createAsyncThunk(
  "leadControls/updateLeadWidget",
  async ({ pageId, widgetId, postbody, widgetType }, thunkAPI) => {
    const response = await API_UPDATE_LEAD_WIDGET(pageId, widgetId, postbody);
    const { status, data } = response;
    const { widgetsLayout } = thunkAPI.getState().leadControls;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const clonedLayout = cloneDeep(widgetsLayout);
    const detailedView =
      data.config.widgetStyle === leadControlsWidgetConstants.DETAILED;
    const rowAndWidgetIndex = findLeadWidgetsIndexInRowWithID(
      data.id,
      clonedLayout
    );
    if (widgetsLayout[rowAndWidgetIndex[0]].detailedView != detailedView) {
      if (detailedView) {
        const newLayout = removeWidgetFromRow(clonedLayout, rowAndWidgetIndex);
        newLayout.push({
          rowId: `row-${nanoid()}`,
          detailedView: detailedView,
          widgets: [data.id],
        });
        thunkAPI.dispatch(updateLeadWidgtesLayout(newLayout));
      } else {
        clonedLayout.splice(rowAndWidgetIndex[0], 1);
        clonedLayout.splice(rowAndWidgetIndex[0], 0, {
          ...widgetsLayout[rowAndWidgetIndex[0]],
          detailedView: detailedView,
        });
        thunkAPI.dispatch(updateLeadWidgtesLayout(clonedLayout));
      }
    }
    return { ...data, widgetType };
  }
);

export const deleteLeadWidget = createAsyncThunk(
  "leadControls/deleteLeadWidget",
  async ({ pageId, widgetId }, thunkAPI) => {
    const response = await API_DELETE_LEAD_WIDGET(pageId, widgetId);
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const { widgetsLayout } = thunkAPI.getState().leadControls;
    const clonedLayout = cloneDeep(widgetsLayout);
    const rowAndWidgetIndex = findLeadWidgetsIndexInRowWithID(
      widgetId,
      clonedLayout
    );
    const [row] = clonedLayout.splice(rowAndWidgetIndex[0], 1);
    const widgets = row.widgets;
    if (widgets.length !== 1) {
      widgets.splice(rowAndWidgetIndex[1], 1);
      clonedLayout.splice(rowAndWidgetIndex[0], 0, {
        ...row,
        widgets: widgets,
      });
    }
    thunkAPI.dispatch(updateLeadWidgtesLayout(clonedLayout));
    thunkAPI.dispatch(resetLeadWidgetConfiguration());
    return widgetId;
  }
);

export const updateLeadPage = createAsyncThunk(
  "leadControls/updateLeadPage",
  async ({ leadControlId, pageId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_LEAD_PAGE(
      leadControlId,
      pageId,
      postBody
    );
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getLeadActionsMetaValue = createAsyncThunk(
  "leadControls/getLeadActionsMetaValue",
  async (_, thunkAPI) => {
    const response = await API_GET_LEAD_ACTIONS_METADATA();
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const updateLeadActions = createAsyncThunk(
  "leadControls/updateLeadActions",
  async ({ leadControlId, actionId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_LEAD_ACTIONS(
      leadControlId,
      actionId,
      postBody
    );
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const saveLeadCard = createAsyncThunk(
  "leadControls/saveLeadCard",
  async (_, thunkAPI) => {
    const { selectedLeadControl, selectedLeadCard, isLeadConfigurationDirty } =
      thunkAPI.getState().leadControls;
    if (
      isLeadConfigurationDirty &&
      selectedLeadControl?.leadCard?.config?.title
    ) {
      thunkAPI.dispatch(
        updateLeadCard(
          formatLeadCardConfig(selectedLeadControl, selectedLeadCard)
        )
      );
    }
  }
);

export const saveLeadPage = createAsyncThunk(
  "leadControls/saveLeadPage",
  async (_, thunkAPI) => {
    const {
      selectedLeadControl,
      widgetsLayout,
      leadPageMenus,
      isLeadConfigurationDirty,
    } = thunkAPI.getState().leadControls;
    const configuaredWidgets = widgetsLayout.length !== 0;
    const configuaredMenus = leadPageMenus.selectedMenus.length !== 0;
    if (isLeadConfigurationDirty && (configuaredWidgets || configuaredMenus)) {
      thunkAPI.dispatch(
        updateLeadPage(
          formatLeadPageConfig(
            selectedLeadControl,
            widgetsLayout,
            leadPageMenus
          )
        )
      );
    }
  }
);

export const saveLeadActions = createAsyncThunk(
  "leadControls/saveLeadActions",
  async (_, thunkAPI) => {
    const { selectedLeadControl, leadActions, isLeadConfigurationDirty } =
      thunkAPI.getState().leadControls;
    const configuaredLeadActions = leadActions.selectedActions.length !== 0;
    if (isLeadConfigurationDirty && configuaredLeadActions) {
      thunkAPI.dispatch(
        updateLeadActions(
          formatLeadActionConfig(selectedLeadControl, leadActions)
        )
      );
    }
  }
);

const leadControlsSlice = createSlice({
  name: "leadControls",
  initialState,
  reducers: {
    addToastMessageFromLeadControls(state, action) {
      state.toastMessages.push(action.payload);
    },
    resetToastMessagesFromLeadControls(state) {
      state.toastMessages.shift();
    },
    changeSelectedLeadType(state, action) {
      state.selectedLeadType = action.payload;
    },
    updateLeadWidgtesLayout(state, action) {
      state.widgetsLayout = action.payload;
      state.isLeadConfigurationDirty = true;
    },
    changeSelectedLeadWidget(state, action) {
      const { mode, widgetTitle, widgetDetails } = action.payload;
      state.selectedLeadWidget.mode = mode;
      state.selectedLeadWidget.widgetTitle = widgetTitle;
      state.selectedLeadWidget.widgetDetails = widgetDetails;
    },
    createLeadWidgetDefinition(state, action) {
      state.selectedWidgetConfiguration = {
        metaData: action.payload.metaData,
        metaValue: {},
      };
    },
    updateLeadWidgetDefinition(state, action) {
      state.selectedWidgetConfiguration = {
        metaData: action.payload.metaData,
        metaValue: action.payload.metaValue,
      };
    },
    changeSelectedLeadPageMenu(state, action) {
      state.leadPageMenus.selectedMenus = action.payload;
      state.isLeadConfigurationDirty = true;
    },
    changeLeadActions(state, action) {
      state.leadActions = action.payload;
      state.isLeadConfigurationDirty = true;
    },
    changeSelectedLeadCard(state, action) {
      state.selectedLeadCard = action.payload;
    },
    resetLeadWidgetConfiguration(state) {
      state.selectedLeadWidget = {
        mode: null,
        widgetTitle: null,
        widgetDetails: {},
      };
      state.selectedWidgetConfiguration = {
        metaData: {},
        metaValue: {},
      };
    },
    setLeadConfigurationDirty(state, action) {
      state.isLeadConfigurationDirty = action.payload;
    },
    resetLeadControls(state) {
      state.selectedLeadCard = {};
      state.widgetsLayout = [];
      state.leadCard.leadFields = [];
      state.isLeadConfigurationDirty = false;
      state.leadActions = {
        actions: [],
        selectedActions: [],
      };
      state.leadPageMenus.selectedMenus = [];
    },
    resetSelectedLeadControls(state) {
      state.selectedLeadType = null;
      state.selectedLeadControl = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeadTypes.fulfilled, (state, action) => {
      state.isLeadTypeLoading = false;
      state.leadTypes = action.payload;
    });
    builder.addCase(getLeadTypes.pending, (state) => {
      state.isLeadTypeLoading = true;
    });
    builder.addCase(getLeadTypes.rejected, (state, action) => {
      state.isLeadTypeLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(getLeadFields.fulfilled, (state, action) => {
      state.leadCard.allLeadFields = action.payload;
    });
    builder.addCase(getLeadFields.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(getLeadCardMetaData.fulfilled, (state, action) => {
      const leadCardMetaData = action.payload;
      state.leadCard.leadActions = leadCardMetaData.cardActions;
    });
    builder.addCase(getLeadCardMetaData.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(updateLeadCard.fulfilled, (state, action) => {
      state.selectedLeadControl.leadCard = action.payload;
      const { config } = action.payload;
      state.selectedLeadCard.visibleCardActions = config.actions;
      state.selectedLeadCard.visibleCardFields = config.leadDetails;
      state.selectedLeadCard.isFieldsEnabledInLeadList =
        config.useLeadDetailsInListCard;
      state.selectedLeadCard.isActionsEnabledInLeadList =
        config.useActionsInListCard;
      state.isLeadConfigurationDirty = false;
      state.isLeadConfigSaveLoading = false;
    });
    builder.addCase(updateLeadCard.pending, (state) => {
      state.isLeadConfigSaveLoading = true;
    });
    builder.addCase(updateLeadCard.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
      state.isLeadConfigSaveLoading = false;
    });
    builder.addCase(addLeadControls.fulfilled, (state, action) => {
      state.selectedLeadControl = action.payload;
      const { leadTypeId } = action.payload;
      const leadTypes = state.leadTypes;
      const selectedLeadType = leadTypes?.find(
        (leadType) => leadType.LeadTypeId === leadTypeId
      );
      const allLeadFields = state.leadCard.allLeadFields;
      state.leadCard.leadFields =
        selectedLeadType?.LeadTypeId !== "default"
          ? allLeadFields.filter((field) =>
              selectedLeadType?.FieldConfiguration?.split(", ").includes(
                field.schemaName
              )
            )
          : allLeadFields;
    });
    builder.addCase(addLeadControls.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(updateLeadControls.fulfilled, (state, action) => {
      state.selectedLeadControl = action.payload;
      const { leadTypeId } = action.payload;
      const leadTypes = state.leadTypes;
      const selectedLeadType = leadTypes?.find(
        (leadType) => leadType.LeadTypeId === leadTypeId
      );
      const allLeadFields = state.leadCard.allLeadFields;
      state.leadCard.leadFields =
        selectedLeadType?.LeadTypeId !== "default"
          ? allLeadFields.filter((field) =>
              selectedLeadType?.FieldConfiguration?.split(", ").includes(
                field.schemaName
              )
            )
          : allLeadFields;
    });
    builder.addCase(updateLeadControls.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(getAllLeadPageWidgets.fulfilled, (state, action) => {
      state.leadWidgets.widgetTemplates.list = action.payload.leadWidgets;
      state.leadWidgets.widgetTemplates.status = globalConstants.FULFILLED;
    });
    builder.addCase(getAllLeadPageWidgets.pending, (state) => {
      state.leadWidgets.widgetTemplates.status = globalConstants.PENDING;
    });
    builder.addCase(getAllLeadPageWidgets.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
      state.leadWidgets.widgetTemplates.status = globalConstants.REJECTED;
    });
    builder.addCase(updateLeadPage.fulfilled, (state, action) => {
      state.selectedLeadControl.leadPage = action.payload;
      state.isLeadConfigurationDirty = false;
      state.isLeadConfigSaveLoading = false;
    });
    builder.addCase(updateLeadPage.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
      state.isLeadConfigSaveLoading = false;
    });
    builder.addCase(updateLeadPage.pending, (state) => {
      state.isLeadConfigSaveLoading = true;
    });
    builder.addCase(
      getLeadPageWidgetMetaData.fulfilled,
      (state, { payload }) => {
        const { _widgetMetaData, widgetIdentifier } = payload;
        state.selectedWidgetConfiguration.metaData =
          _widgetMetaData[widgetIdentifier];
        state.leadWidgets.widgetMetaData = _widgetMetaData;
      }
    );
    builder.addCase(getLeadPageWidgetMetaData.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(
      getLeadPageWidgetMetaValue.fulfilled,
      (state, { payload }) => {
        if (payload) state.selectedWidgetConfiguration.metaValue = payload;
      }
    );
    builder.addCase(getLeadPageWidgetMetaValue.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(addLeadWidget.fulfilled, (state, action) => {
      const response = action.payload;
      state.leadWidgetConfiguration[response.id] = response;
    });
    builder.addCase(addLeadWidget.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(updateLeadWidget.fulfilled, (state, action) => {
      const response = action.payload;
      state.leadWidgetConfiguration[response.id] = response;
    });
    builder.addCase(updateLeadWidget.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(deleteLeadWidget.fulfilled, (state, action) => {
      const response = action.payload;
      const widgetConfiguration = state.leadWidgetConfiguration;
      delete widgetConfiguration[response];
      state.leadWidgetConfiguration = widgetConfiguration;
    });
    builder.addCase(deleteLeadWidget.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(getLeadPageMenuTabs.fulfilled, (state, action) => {
      const menus = action.payload;
      const systemMenuTabs = formatLeadTabsWithIsCustom(
        menus.systemMenuTabs,
        false
      );
      const customMenuTabs = formatLeadTabsWithIsCustom(
        menus.customMenuTabs,
        true
      );
      state.leadPageMenus.menus = [...systemMenuTabs, ...customMenuTabs];
    });
    builder.addCase(getLeadPageMenuTabs.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(getLeadActionsMetaValue.fulfilled, (state, action) => {
      const menus = action.payload;
      const systemLeadActions = formatLeadTabsWithIsCustom(
        menus.systemLeadActions,
        false
      );
      const customLeadActions = formatLeadTabsWithIsCustom(
        menus.customLeadActions,
        true
      );
      state.leadActions.actions = [...systemLeadActions, ...customLeadActions];
    });
    builder.addCase(getLeadActionsMetaValue.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(updateLeadActions.fulfilled, (state, action) => {
      state.isLeadConfigurationDirty = false;
      state.selectedLeadControl.leadAction = action.payload;
      state.isLeadConfigSaveLoading = false;
    });
    builder.addCase(updateLeadActions.pending, (state) => {
      state.isLeadConfigSaveLoading = true;
    });
    builder.addCase(updateLeadActions.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
      state.isLeadConfigSaveLoading = false;
    });
  },
});

export const {
  addToastMessageFromLeadControls,
  resetToastMessagesFromLeadControls,
  changeSelectedLeadType,
  updateLeadWidgtesLayout,
  changeSelectedLeadWidget,
  resetLeadWidgetConfiguration,
  changeSelectedLeadPageMenu,
  changeLeadActions,
  changeSelectedLeadCard,
  setLeadConfigurationDirty,
  resetLeadControls,
  resetSelectedLeadControls,
} = leadControlsSlice.actions;

export default leadControlsSlice.reducer;
