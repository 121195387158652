import React, { useEffect, useState } from "react";
import Text from "../../components/Text";
import styles from "./styles.module.css";
import MoreOptionsPopup from "../../components/MoreOptionsPopup";
import { ReactComponent as DropDownIcon } from "../../assets/svgIcons/lsq_chevron_down.svg";
import { leadControlsMoreOptions } from "../../utils/leadControlsUtils";
import { leadControlsConstants } from "../../constants/globalConstant";

export const LeadTitleEditor = ({
  displayText,
  onSaveHandler,
  setShowLeadType,
}) => {
  const [text, setText] = useState(displayText);
  const [editMode, setEditMode] = useState({ state: false, value: text });

  const menuItemClickHandler = (label) => {
    switch (label) {
      case leadControlsConstants.RENAME:
        return setEditMode({
          state: true,
          value: text,
        });
      case leadControlsConstants.CHANGE_LEAD_TYPE:
        return setShowLeadType(true);
      default:
        return;
    }
  };

  const handleSave = (event) => {
    if (event.target.value) {
      setText(event.target.value);
      setEditMode({ state: false, value: text });
      onSaveHandler(text);
    } else {
      setText(editMode.value);
      setEditMode({ state: false, value: text });
    }
    event.stopPropagation();
  };

  useEffect(() => {
    setText(displayText);
  }, [displayText]);

  return (
    <>
      {editMode.state ? (
        <input
          type="text"
          maxLength={30}
          value={text}
          onBlur={handleSave}
          onChange={(e) => setText(e.target.value)}
          className={styles["text-edit"]}
          autoFocus
          data-testid="input-box"
        />
      ) : (
        <div data-testid="lead-control-name" className="flex">
          <Text type="T2">{displayText}</Text>
        </div>
      )}
      <MoreOptionsPopup
        options={leadControlsMoreOptions}
        onClickIcon={<DropDownIcon />}
        data-testid="menu-more-btn"
        optionStyle={{ padding: "0.5rem" }}
        menuItemClickHandler={menuItemClickHandler}
      ></MoreOptionsPopup>
    </>
  );
};
